.slide-title{
  margin-bottom: 2rem;
}

.events-filter{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 1rem;
  a.active{
    @extend .color-red;
  }
  a:hover{
    @extend .color-red;
  }
}
.events-grid{
  display: grid;
  border-bottom: 1px solid;
  p{
    margin-bottom: 0;
    &:last-of-type{
      margin-bottom: 0;
    };
  }
}

.block-type-event{
  display: none;
  &.show{
    display: block;
  }
}

.event-grid-item{
  padding: 0.8em 0;
  border-top: 1px solid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  display: block;
  @include mq(md){
    display: grid;
    grid-template-columns: repeat(3, 33.3333%);
  }
  span{
    margin-right: 1em;
  }
  p.txt-center{
    @include mq(md){
      text-align: center;
    }
    text-align: left;
  }
  p.txt-justify{
    text-align: left;
    hyphens: none;
    @include mq(md){
      text-align: justify;
    }
  }
  a.collapsible{
    position: absolute;
    top:0.6em;
    @include mq(md){
      position: unset;
      top: unset;
    }
  }
  .content-inner{
    padding: 0;
    padding-top: 0.2rem;
  }
}

.event-grid-item .cover-img{
  max-width: 50%;
  margin-bottom: 0.5rem;
  .landscape{
    max-width: 55%;
  }
  @include mq(md){
    max-width: 25%;
    margin: 0 auto;
    margin-bottom: 0.5rem;
    &.landscape{
      max-width: 30%;
    }
  }
  picture{
    display: inline !important;
  }
}

.event-grid-item .cover-img picture{
  width: 100%;
}

.no-events{
  padding: 0.8em 0;
  border-top: 1px solid;
}
