// Main Footer

main[data-id="archives"], main[data-id="archive"], main[data-id="harvest"]{
  footer{
    padding-top: 0;
  }
}

footer{
  display: flex;
  gap: 1em;
  padding-top: 8rem;
  grid-column: span 12;
  align-items: end;
  justify-content: space-between;
  .active{
    @extend .underline;
  }
  .footer-menu-item:nth-of-type(2){
    display: none;
  }
  .text-xs{
    @include fs(main);
  }
  @include mq(md){
    display: flex;
    justify-content: start;
    .footer-menu-item:nth-of-type(2){
      display: block;
    }
    .footer-menu-item:nth-of-type(3){
      margin-left: auto;
    }
    .text-xs{
      font-size: var(--text-xs);
    }
  }
}



.footer .footer-menu-item a{
  &:hover{
    @extend .underline;
  }
}

.inside-footer .footer-menu-item a{
  &:hover{
    @extend .underline;
  }
}

// Inside Carousel Footer

.inside-footer{
  position: fixed;
  bottom: 0;
  color: white;
  width: 100%;
  z-index: 999;
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  grid-column-gap: 1rem;
  grid-template-rows: 1fr;
  align-items: end;
  .logo{
    filter: invert(100);
    width: 6em;
    padding: 1.25rem;
    @include mq(md){
      padding: 1.6rem 1.6rem 1.6rem 1.7rem;
    }
  }
  .row{
    grid-column: 1/13;
    &.grid{
      display: grid;
    }
    .row-content-inner{
      grid-column: 3/11;
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
    p{
      margin-bottom: 0;
    }
    p:last-of-type{
      border-top: none;
    }
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 0.2rem 1.875rem;
    cursor: pointer;
    &:last-of-type{
      border-top: none;
      border-bottom: none;
      cursor: default;
    }
  }
  @include mq(md){
    grid-column-gap: 0rem;
  }
}

.swiper-v-trigger:hover{
  @extend .color-red;
}


.inside-footer .row{
  display: none;
  &.grid{
    display: none;
  }
  @include mq(md){
    display: flex;
    &.grid{
      display: grid;
    }
  }
}

.inside-footer .calendar-info{
  @include mq(md){
    display: none;
  }
  display: block;
  align-self: end;
  padding: 1.25rem;
  padding-left: 0;
  h4{
    margin-bottom: 0.1rem;
  }
  .swiper-v-trigger{
    &:hover{
      color: inherit;
    }
  }

}
