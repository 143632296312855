main[data-temp='product'], main[data-temp='product-variants'] {
  padding: 0;
}

main[data-temp='product'], main[data-temp='product-variants']{
  footer{
    padding: 1.55rem 1.875rem;
    padding-top: 8rem;
  }
}

.gallery{
  @include mq(md){
    min-height: 100vh;
    height: 100%;
  }

}
.gallery.single{
  @include mq(md){
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.product-info{
  display: grid;
  padding: 1.25rem;
  grid-template-areas: "title stock"
                       "variant cart-button"
                       "empty-1 details"
                       "description description"
                       "delay delay";
  grid-auto-columns: 1fr 1fr;
  grid-template-rows: repeat(4, min-content);
  grid-row-gap: 2.5rem;
  grid-column-gap: 1rem;
  @include mq(md){
    grid-column-gap: 1rem;
    padding-top: 6em;
    padding-left: 1.4rem;
    padding-right: 1.825rem;
    padding-bottom: 1.5em;
    height: 100vh;
    grid-template-areas: "title stock"
                       "variant cart-button"
                       "empty-1 details"
                       "description description"
                       "empty-2 delay";
  }
}

.add-to-cart.button-slim{
  line-height: 1.65em;
  position: relative;
  height: 100%;
  padding: 0;
}

.product-info{
  align-items: start;
  justify-content: start;
  color: black;
  .price{
    font-feature-settings: "lnum";
  }
  .details{
    grid-area: details;
    margin-top: 0.7rem;
    font-feature-settings: "lnum";
  }
  .add-to-cart{
    grid-area: cart-button;
  }
  .variant{
    grid-area: variant;
  }
  .description{
    grid-area: description;
  }
  .delay-and-preorder{
    grid-area: delay;
    align-self: end;
  }
}


.related-products{
  @include mq(md){
    margin-top: 8em;
  }
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: max-content 1fr;
  .related-category{
    grid-column: span 2;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: max-content 1fr;
    @include mq(md){
      grid-template-columns: repeat(2, 1fr);
    }
    h4{
      grid-column: span 2;
      margin-bottom: 1em;
    }
    picture img{
      height: 100%;
      object-fit: cover;
    }
    .product-grid-item.landscape{
      grid-column: span 2;
      &:last-of-type{
        display: none;
      }
      &:last-of-type.solo{
        display: block;
      }
    }
    .product-grid-item.landscape + .product-grid-item.portrait{
      /*display: none;*/
    }
  }
}
