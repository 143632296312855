main[data-id="harvest"] {
  .content-wrapper {
    grid-column-start: 1;
    grid-column-end: 13;
  }
}


.harvest .gallery-grid{
  padding: 0 5.5555555556vw;
  margin-bottom: 6.5vw;
  @include mq(md){
    padding: 0;

  }
  .gallery-item{
    grid-column: 4/10;
    margin-bottom: calc((5.55555555556vw + 1.25rem) * 3);
    @include mq(md){
      margin: 0;
    }
    &.duo{
      grid-column: span 6;
    }

  }
  picture{
    margin-bottom: 0;
  }
  picture.portrait{
    grid-column: span 6;
    height: 100%;
    img{
      height: 100%;
      object-fit: cover;
    }
  }
}

.gallery-item a{
  display: inline-block;
  margin-top: 0.8rem !important;
  @include mq(md){
    padding-left: 0vw;
  }
  &:hover{
    @extend .color-red;
  }
}

.harvest .archives-grid .archives-item{
  align-items: center;
  flex: 1;
  .archives-item-title{
    flex: none;
    &:first-of-type{
      margin-bottom: 1.3em;
    }
  }
  a{
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

.harvest .archives-grid .archives-item{
  grid-column: span 6;
  padding: 0 5.5555555556vw;
  @include mq(md){
    padding: 0 calc((100vw - 4rem)/12);
  }
  &.center-item{
    grid-column: 4/10;
  }
}

.harvest .archives-grid .archives-item .portrait{
  @include mq(md){
    padding: 0 3vw;
  }
}
