main[data-id="archives"] {
  .content-wrapper {
    grid-column-start: 1;
    grid-column-end: 13;
  }
}




h1.page-title.margin-m{
  margin-bottom: 7.3rem;
  margin-top: 2.5rem;
}

h2.page-title.margin-m{
  margin-top: 10.45rem;
  margin-bottom: 7.3rem;
}

h1.page-title.margin-s{
  margin-bottom: 3.75rem;
  margin-top: 2.5rem;
}

h2.page-title.margin-s{
  margin-top: 2.3rem;
  margin-bottom: 4.4rem;
}

.page-description{
  margin-bottom: 3.3rem;
}

.h3-margin-m{
  margin-bottom: 0.45rem;
}

.h3-margin-s{
  margin-bottom: 0rem;
}


.page-description{
  grid-column-start: 3 !important;
  grid-column-end: 11 !important;
  h3{
    @extend .h3-margin-m;
  }
  text-align: left;
  hyphens: none;
  @include mq(md){
    text-align: justify;
  }
}

.archives-credits{
  grid-column-start: 3 !important;
  grid-column-end: 11 !important;
  margin-bottom: 8.5rem;
  h3{
    @extend .h3-margin-s;
  }
  h3 + p{
    margin-bottom: 1.5rem;
  }
}













.archives-item {
  display: flex;
  grid-column: span 6;
  align-items: center;
  &.center-item{
    grid-column: 4/10;
  }
  padding: 0 5.55555555556vw;
  margin-bottom: calc((5.55555555556vw + 1.25rem) * 2.5);
  @include mq(md){
    margin-bottom: 6.5vw;
    padding: 0 calc((100vw - 4rem)/12);
  }
}

.archives-item a{
  display: flex;
  flex-direction: column;
  flex: 1;
}

.archives-item a:hover {
  @extend .color-red;
}

.archives-item-title {
  margin-bottom: 1.3em;
  flex: 1;
  display: flex;
  align-items: end;
  justify-content: center;
  align-self: center;
}

.archives-item:first-of-type .archives-item-title {
  margin-bottom: -0.1em;
  margin-bottom: 1.3em;
}

.archives-item-subtitle {
  margin-top: 0.7em;
}

.archives-grid .archives-item.big {
  grid-column: 4/10;
  padding: 0 5.5555555556vw;
  @include mq(md){
    padding: 0;
  }
  picture{
    @include mq(md){
      /*padding: 0 calc(((100vw - 1.875rem * 2)/12)*3);*/
    }
  }
}


